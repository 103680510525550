import Vue from 'vue';
import Vuex from 'vuex';
import Api from '../services/api.js';

import {
    VALIDATION_TYPE_APP,
    VALIDATION_TYPE_TELEGRAM,
    VALIDATION_TYPE_HOTPTOKEN,
    VALIDATION_TYPE_SMS,
    VALIDATION_TYPE_TOTPTOKEN,
    VALIDATION_METHOD_ONLINE,
    VALIDATION_METHOD_OFFLINE
  } from '../config.js';
import { CONNECTION } from '../services/hub.js';
import { HubConnectionState } from '@microsoft/signalr';
import { eventBus, EMIT_SEND_HIDDEN_FORM } from '../event-bus.js';
import { formatPhoneNumber } from '../core.js'

Vue.use(Vuex);

Vue.filter('phone', function (value) {
  return !!value && formatPhoneNumber(value) || '';
});

class WebSocketState {
  constructor() {
    this._resolveWebSocketState = () => {};
    this._webSocketState = new Promise((resolve) => {
      this._resolveWebSocketState = resolve;
    });
  }

  resolve() {
    this._resolveWebSocketState();
  }

  asAwaitable() {
    return this._webSocketState;
  }
}

export default new Vuex.Store({
  state: {
    requestId: null,

    authType: null,
    authTypeItemActive: null,

    codeValidationLength: 6,
    code: null,
    showRefresh: false,

    hiddenFormShow: true,
    hiddenFormMethod: null,
    hiddenFormAction: null,
    hiddenFormInputValue: null,
    hiddenFromTarget: null,

    animateButton: false,

    clickedLink: false,
    
    currLocale: null,
    logo: null,

    connectionReady: false,
    connectionReadyState: new WebSocketState()
  },
  mutations: {
    MUTATE_ANIMATE_BUTTON(state, value) {
      state.animateButton = value;
    },
    MUTATE_HIDDEN_FORM_SHOW(state, value) {
      state.hiddenFormShow = value;
    },
    MUTATE_HIDDEN_FORM_METHOD(state, value) {
      state.hiddenFormMethod = value;
    },
    MUTATE_HIDDEN_FORM_ACTION(state, value) {
      state.hiddenFormAction = value;
    },
    MUTATE_HIDDEN_FORM_INPUT_VALUE(state, value) {
      state.hiddenFormInputValue = value;
    },
    MUTATE_HIDDEN_FORM_TARGET(state, value) {
      state.hiddenFromTarget = value;
    },

    MUTATE_AUTH_TYPE( state, value) {
      state.code = null;
      state.authType = value;
      state.showRefresh = false;
    },
    MUTATE_AUTH_TYPE_SELECTED(state, value) {
      state.code = null;
      state.authTypeItemActive = value;
    },
    MUTATE_CODE(state, value) {
      state.code = value;
    },
    MUTATE_CODE_VALIDATION_LENGTH(state, value) {
      state.codeValidationLength = value;
    },
    MUTATE_SHOW_RESEND_CODE(state, value) {
      state.showRefresh = value;
    },
    MUTATE_CLICKED_LINK(state, value) {
      state.clickedLink = value;
    },
    MUTATE_CURR_LOCALE(state, value) {
      state.currLocale = value;
    },
    MUTATE_LOGO(state, value) {
      state.logo = value;
    },
    MUTATE_CONNECTION_READY(state, value) {
      state.connectionReady = value;
      state.connectionReadyState.resolve();
    },
    MUTATE_REQUEST_ID(state, value) {
      state.requestId = value;
    },
  },

  actions: {
    SET_ANIMATE_BUTTON({commit}, val) {
      commit('MUTATE_ANIMATE_BUTTON', val);
    },

    SET_CODE({commit, dispatch}, val) {
      commit('MUTATE_CODE', val);
      dispatch('SEND_TOKEN');
    },
    SEND_TOKEN({state}) {
      if (state.authType) {
        switch (state.authType.value) {
          case VALIDATION_TYPE_APP: {

            if (state.authType.preferredMethod === VALIDATION_METHOD_ONLINE && state.authTypeItemActive) {

              if (CONNECTION.state === HubConnectionState.Connected) {

                CONNECTION.invoke("Auth", state.requestId, {
                  Provider: state.authType.value,
                  id: state.authTypeItemActive,
                }).catch(err => {
                  console.error(err.toString());
                });

              } else {

                let interval = setInterval(() => {
                  CONNECTION.invoke("Auth", state.requestId, {
                    Provider: state.authType.value,
                    id: state.authTypeItemActive,
                  }).then(() => {
                    if(CONNECTION.state === HubConnectionState.Connected) {
                      clearInterval(interval);
                    }
                  }).catch(err => {
                    console.error(err.toString());
                  });
                }, 500);

              }

            } else if (state.authType.preferredMethod === VALIDATION_METHOD_OFFLINE) {

              CONNECTION.invoke("Auth", state.requestId, {
                Provider: state.authType.value,
                Code: state.code
              }).catch(err => {
                console.error(err.toString());
              });

            }

            break;
          }

          case VALIDATION_TYPE_TELEGRAM: {

            if (state.authTypeItemActive) {
              if (CONNECTION.state === HubConnectionState.Connected) {

                CONNECTION.invoke("Auth", state.requestId, {
                  Provider: state.authType.value,
                  id: state.authTypeItemActive,
                }).catch(err => {
                  console.error(err.toString());
                });

              } else {

                let interval = setInterval(() => {
                  CONNECTION.invoke("Auth", state.requestId, {
                    Provider: state.authType.value,
                    id: state.authTypeItemActive,
                  }).then(() => {
                    if(CONNECTION.state === HubConnectionState.Connected) {
                      clearInterval(interval);
                    }
                  }).catch(err => {
                    console.error(err.toString());
                  });
                }, 500);

              }

            }

            break;
          }

          case VALIDATION_TYPE_HOTPTOKEN: {

            CONNECTION.invoke("Auth", state.requestId, {
              Provider: state.authType.value,
              Code: state.code
            }).catch(err => {
              console.error(err.toString());
            });
            
            break;
          }

          case VALIDATION_TYPE_TOTPTOKEN: {

            CONNECTION.invoke("Auth", state.requestId, {
              Provider: state.authType.value,
              Code: state.code
            }).catch(err => {
              console.error(err.toString());
            });
            
            break;
          }

          case VALIDATION_TYPE_SMS: {

            if (state.authTypeItemActive && state.code.length === state.codeValidationLength) {

              CONNECTION.invoke("Auth", state.requestId, {
                Provider: state.authType.value,
                id: state.authTypeItemActive,
                Code: state.code
              }).catch(err => {
                console.error(err.toString());
              });

            }

            break;
          }
        }
      }
    },

    SET_HIDDEN_FORM({commit, dispatch}, val) {
      commit('MUTATE_HIDDEN_FORM_SHOW', true);
      commit('MUTATE_HIDDEN_FORM_METHOD', val.method);
      commit('MUTATE_HIDDEN_FORM_ACTION', val.action);
      commit('MUTATE_HIDDEN_FORM_INPUT_VALUE', val.accessToken);
      commit('MUTATE_HIDDEN_FORM_TARGET', val.target);
      setTimeout(() => {
        dispatch('SEND_HIDDEN_FORM');
      }, 100);
    },
    SEND_HIDDEN_FORM({commit}) {
      eventBus.$emit(EMIT_SEND_HIDDEN_FORM);
      commit('MUTATE_HIDDEN_FORM_METHOD', null);
      commit('MUTATE_HIDDEN_FORM_ACTION', null);
      commit('MUTATE_HIDDEN_FORM_INPUT_VALUE', null);
      commit('MUTATE_HIDDEN_FORM_TARGET', null);
      setTimeout(() => {
        commit('MUTATE_HIDDEN_FORM_SHOW', false);
      }, 100);
    },

    SET_CLICKED_LINK({commit}, val) {
      commit('MUTATE_CLICKED_LINK', val);
    },
    SET_LOGO({commit}) {
      Api.getLogo().then((response) => {
        if(response.data.url) {
          commit('MUTATE_LOGO', response.data.url);
        }
      });
    },
    SET_CONNECTION_READY({commit}, val) {
      commit('MUTATE_CONNECTION_READY', val);
    },
  },
  modules: {
  },
  getters: {
    GET_ANIMATE_BUTTON: state => {
      return state.animateButton;
    },

    GET_HIDDEN_FORM_SHOW: state => {
      return state.hiddenFormShow;
    },
    GET_HIDDEN_FORM_METHOD: state => {
      return state.hiddenFormMethod;
    },
    GET_HIDDEN_FORM_ACTION: state => {
      return state.hiddenFormAction;
    },
    GET_HIDDEN_FORM_INPUT_VALUE: state => {
      return state.hiddenFormInputValue;
    },

    GET_AUTH_TYPE: state => {
      return state.authType;
    },
    GET_AUTH_TYPE_SELECTED_VALUE: state => {
      return state.authTypeItemActive;
    },

    GET_CODE: state => {
      return state.code;
    },
    GET_CODE_VALIDATION_LENGTH: state => {
      return state.codeValidationLength;
    },
    GET_SHOW_REFRESH_CODE: state => {
      return state.showRefresh;
    },
    GET_HIDDEN_FORM_TARGET: state => {
      return state.hiddenFromTarget;
    },

    GET_CLICKED_LINK: state => {
      return state.clickedLink;
    },
    GET_CURR_LOCALE: state => {
      return state.currLocale;
    },
    GET_LOGO: state => {
      return state.logo;
    },
    GET_CONNECTION_READY: state => {
      return state.connectionReady;
    },
    GET_REQUEST_ID: state => {
      return state.requestId;
    },
    GET_WEBSOCKET_STATE_AWAITABLE: state => {
      return state.connectionReadyState.asAwaitable();
    }
  }
})